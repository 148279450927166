import { actions } from "./../../store";
import { FIELDS } from "../../store/form/fields";
import { useState, useEffect, useRef } from "react";
import { useForm, FormProvider } from "react-hook-form";
import Button from "../../components/Button";
import PrintIcon from "../../components/PrintIcon";
import PortalView from "./../../components/Global/PortalView";
import ProgressBar from "../../components/progress/ProgressBar";
import ProgressBarLabel from "../../components/progress/ProgressBarLabel";
import TribeDropdown from "../../components/DataDriven/TribeDropdown";
import CompleteSubmission from "../../components/CompleteSubmission";
import { goBack } from "../../utils";
import apiClient from "../../utils/API";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { open } from "../../store/snack/actions";
import Checkbox from "../../components/Checkbox";
import _get from "lodash.get";
import ApplicationSubmitButton from "../../components/Utility/ApplicationSubmitButton";
import ApplicationInfo from "../../utils/dtos/ApplicationInfo";
import CollegeChoiceAnswer from "../../components/answer/CollegeChoiceAnswer";
import ClassStanding from "../../components/answer/question/ClassStanding";
import SectionFormHelper from "../../utils/helper/SectionHelper";
import { Alert } from '@material-ui/lab';
import RequirementSet from "../../utils/dtos/RequirementSet";



const PROGRAM_CODE = "OTSG";

/**
 * renders the Oregon Tribal Student Grant application
 * @returns {Component}
 */
function OTSG() {
    let { year } = useParams();

    const activeYear = parseInt(year);

    const methods = useForm({
        mode: "onBlur",
    });
    const { setValue, handleSubmit, watch } = methods;
    const formState = useSelector((state) => state.form);

    const history = useHistory();
    const handleCancel = () => {
        goBack({
            history,
            defaultRoute: "/dashboard",
        });
    };

    const [showConfirmation, setShowConfirmation] = useState(false);

    const sectionGroup = new SectionFormHelper(useState(null));
    const allAppSection = sectionGroup.create(useState(null), null, [
        FIELDS.OTSG__ATTENDING_FALL_TERM,
        FIELDS.OTSG__ATTENDING_WINTER_TERM,
        FIELDS.OTSG__ATTENDING_SPRING_TERM,
        FIELDS.OTSG__ENROLLED_TRIBE,
        FIELDS.COLLEGE_CHOICE,
        FIELDS.COLLEGE_STANDING,
        FIELDS.COLLEGE_DUAL_ENROLLED,
        FIELDS.FIRST_NAME,
        FIELDS.MIDDLE_INITIALS,
        FIELDS.LAST_NAME,
        FIELDS.OTHER_NAMES,
    ]);

 
    const applicationYear = `${activeYear}-${activeYear + 1}`;

    const [applicationData, setApplicationData] = useState(null);
    const app = new ApplicationInfo(applicationData);

    const [completionData, setCompletionData] = useState(null);
    const sectionCompletion = new RequirementSet(completionData); 
    const collegeCompletion = sectionCompletion.getField(FIELDS.COLLEGE_CHOICE);

    useEffect(async () => {
        const currentSeasonResult =  await apiClient.getSeason(PROGRAM_CODE, year);                   
        if (!currentSeasonResult.IsAvailable){
            dispatch(
                open({
                    message: "This application is no longer available",
                    severity: "success",
                })
            );
            handleCancel();
            return;
        }

        const [appResult, answers, completionResult] = await Promise.all([
            apiClient.getApplication(PROGRAM_CODE, activeYear),
            sectionGroup.fetchAnswers(activeYear),
            apiClient.getCompletionReport("OTSG", activeYear, [FIELDS.COLLEGE_CHOICE])
        ]);
        setApplicationData(appResult);
        setCompletionData(completionResult); //set completion data
    }, [])




    const dispatch = useDispatch();

    const [saveLoading, setSaveLoading] = useState(false);

  const onSave = async (data) => {
    // console.log("Save", data);
    try {
      setSaveLoading(true)
      // console.log(data, e);
      await actions.submitForm(data, [], ["Agreement"], activeYear);
      dispatch(
        open({
          message: "Saved Successfully",
          severity: "success",
        })
      );
      var result = await apiClient.getCompletionReport("OTSG", activeYear, [FIELDS.COLLEGE_CHOICE]);
      setCompletionData(result);
    } catch (e) {
      console.error(e);
      dispatch(
        open({
          message: e.message,
          severity: "success",
        })
      );
    } finally {
      setSaveLoading(false);
    }
  };

    const onError = () => {
        // console.log("ERROR", errors, e);
    };

    const onEdit = () => {
        setShowConfirmation(false);
        window.location.reload(true); /* 3722/SP236: Calling components aren't keeping application state so, reload the page from server. */
    }

    const [submitLoading, setSubmitLoading] = useState(false);

    const actualSubmit = async () => {
        const actualSubmission = async (data) => {
            await actions.submitForm(data, [], ["Agreement"], activeYear);
            await apiClient.submitApplication(PROGRAM_CODE, activeYear);
            // console.log(data, e);
            setShowConfirmation(true);
        };
        try {
            setSubmitLoading(true);
            const execSubmission = handleSubmit(actualSubmission, onError);
            await execSubmission();
            dispatch(
                open({
                    message: "Submitted Successfully",
                    severity: "success",
                })
            );
        } catch (e) {
            console.error(e);
            dispatch(
                open({
                    message: e.message,
                    severity: "success",
                })
            );
        } finally {
            setSubmitLoading(false);
        }

    };

   const watchCollege =watch(FIELDS.COLLEGE_CHOICE)

    const watchFallTerm = watch(FIELDS.OTSG__ATTENDING_FALL_TERM);
    const watchWinterTerm = watch(FIELDS.OTSG__ATTENDING_WINTER_TERM)
    const watchSpringTerm = watch(FIELDS.OTSG__ATTENDING_SPRING_TERM)
    useEffect(() => {
        console.log(FIELDS.OTSG__ATTENDING_FALL_TERM)
        console.log(watchFallTerm)
        if (!watchFallTerm) {
            setValue(FIELDS.OTSG__ATTENDING_FALL_TERM, false);
        }
    }, [watchFallTerm])
    useEffect(() => {
        console.log(FIELDS.OTSG__ATTENDING_WINTER_TERM)
        console.log(watchWinterTerm)
        if (!watchWinterTerm) {
            setValue(FIELDS.OTSG__ATTENDING_WINTER_TERM, false);
        }
    }, [watchWinterTerm])
    useEffect(() => {
        console.log(FIELDS.OTSG__ATTENDING_SPRING_TERM)
        console.log(watchSpringTerm)
        if (!watchSpringTerm) {
            setValue(FIELDS.OTSG__ATTENDING_SPRING_TERM, false);
        }
    }, [watchSpringTerm, watchFallTerm, watchWinterTerm])


    const watchTribe = watch(FIELDS.OTSG__ENROLLED_TRIBE)

    /**
     * determine progress based on current watch status
     */
    const progress = {};
    if (watchTribe) {
        progress.tribe = 'complete'
    } else {
        progress.tribe = 'inProgress'
    }
    if ((watchFallTerm && watchFallTerm !== false) ||
        (watchWinterTerm && watchWinterTerm !== false) ||
        (watchSpringTerm && watchSpringTerm !== false)) {
        console.log('terms showing complete')
        progress.terms = 'complete'
    } else {
        progress.terms = 'inProgress'
    } 
    if (watchCollege) {
        progress.college = 'complete'
    } else {
        progress.college = 'inProgress'
    }
    
    const submitDisabled = (progress.tribe !== 'complete' || progress.terms !== 'complete' || progress.college !== 'complete');
    const isEligible = true;
    let completeSectionCount = 0;
    for (const i in progress) {
        if (progress[i] === "complete")
            completeSectionCount++;
    }
    //give 2% for submit and 25% for profile
    const progressPercent = completeSectionCount === 3 && app.isSubmitted ? 100 : Math.round(25 + 73 * (completeSectionCount / 3) + (app.isSubmitted ? 2 : 0));


    const tribeRef = useRef();
    const collegeRef = useRef();
    const termsRef = useRef();
    const submitRef = useRef();

    return (
        <FormProvider {...methods}>
            <PortalView>
                <div name="ApplicationsPage">
                    <div className="page-intro">
                        <div className="wrapper">
                            <div className="page-intro__layout">
                                <div className="page-intro__icon">
                                    <img src="/MyApplictionsIcon.svg" alt="Applications icon" style={{ marginTop: ".5rem" }} />
                                </div>
                                <div className="page-intro__content rhythm">
                                    <h1 className="heading heading--h1">
                                        OTSG Grant
                                        <PrintIcon />
                                    </h1>
                                    <h2 className="heading heading--h2">{applicationYear}</h2>
                                    <a href="/dashboard">Return to Dashboard</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    {showConfirmation ? (
                        <div>
                            <CompleteSubmission onEdit={onEdit} details={{
                                title: "OTSG",
                                code: PROGRAM_CODE,
                            }} />
                        </div>
                    ) : (
                        <div className="wrapper">
                            <div className="application-progress component">

                                <div className="progress-bar rhythm">
                                    <h3 className="heading heading--h3">
                                        Application Progress
                                    </h3>

                                    <ProgressBar progress={progressPercent} />
                                    <div className="progress-bar__labels">
                                        <ProgressBarLabel title="Profile" state="complete" />
                                        <ProgressBarLabel title="Tribe" state={progress.tribe} sectionRef={tribeRef} />
                                        <ProgressBarLabel title="College" state={progress.college} sectionRef={collegeRef} />
                                        <ProgressBarLabel title="Terms" state={progress.terms} sectionRef={termsRef} />
                                        <ProgressBarLabel title="Submit" state={app.isSubmitted ? "complete" : "incomplete"} sectionRef={submitRef} />
                                    </div>

                                </div>
                            </div>{/* application-progress */}

                            <div className="divider" />

                            <div className="form-wrapper rhythm">

                                {/* Tribe Selection */}
                                <div className="tribe-selection" ref={tribeRef}>
                                    <div className="form-section">
                                        <div className="form-section__header">
                                            <h2 className="heading heading--h3">Enrolled Tribe</h2>
                                        </div>

                                        <div className="wrapper rhythm">
                                            <h3 className="heading heading--h4 academic-year">Your Enrolled Tribe</h3>
                                            <TribeDropdown
                                                name={FIELDS.OTSG__ENROLLED_TRIBE}
                                                label='Your Selected Tribe'
                                            />
                                        </div> {/* end wrapper */}
                                    </div> {/* end form-section */}
                                </div>

                                <div className="college-terms" ref={collegeRef}>
                                    <div className="form-section">
                                        <div className="form-section__header">
                                            <h2 className="heading heading--h3">College Choice</h2>
                                        </div>
                                        {collegeCompletion?.isDisqualifying === true &&
                                            <div item>
                                                <Alert severity="error" className="alert alert--error">
                                                    <p>
                                                        <strong>
                                                            The&nbsp;
                                                            <a href="https://oregonstudentaid.gov/grants/oregon-opportunity-grant/list-of-participating-schools">college you selected</a>
                                                            &nbsp;is not eligible to receive the Oregon Tribal Student Grant.
                                                    </strong>
                                                    </p>
                                                </Alert>
                                            </div>
                                        }
                                        <div className="wrapper rhythm">
                                            <CollegeChoiceAnswer answer={allAppSection.getAnswerByField(FIELDS.COLLEGE_CHOICE)}  />

                                            <ClassStanding answer={allAppSection.getAnswerByField(FIELDS.COLLEGE_STANDING)} />
                                        </div>
                                    </div>
                                </div>

                                {/* College Terms */}
                                <div className="college-terms" ref={termsRef}>
                                    <div className="form-section">
                                        <div className="form-section__header">
                                            <h2 className="heading heading--h3">College Terms</h2>
                                        </div>

                                        {/* Desktop - Current Academic Year */}
                                        <div className="wrapper rhythm">
                                            <h3 className="heading heading--h4 academic-year">{applicationYear} Academic Year</h3>
                                            <div className="table-wrapper">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th>Term</th>
                                                            <th>Attending?</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Fall Term</td>
                                                            <td><Checkbox name={FIELDS.OTSG__ATTENDING_FALL_TERM} label="Yes" /></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Winter Term</td>
                                                            <td><Checkbox name={FIELDS.OTSG__ATTENDING_WINTER_TERM} label="Yes" /></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Spring Term</td>
                                                            <td><Checkbox name={FIELDS.OTSG__ATTENDING_SPRING_TERM} label="Yes" /></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div> {/* end wrapper */}
                                    </div> {/* end form-section */}
                                </div>

                                {/* Save/Cancel Buttons */}
                                <div className="grant-action-buttons" ref={submitRef}>
                                    <Button
                                        name="Cancel"
                                        label="Cancel"
                                        variant="text"
                                        onClick={handleCancel}
                                    />
                                    <Button
                                        loading={saveLoading}
                                        name="Save"
                                        label="Save"
                                        disabled={!isEligible}
                                        type="submit"
                                        onClick={handleSubmit(onSave, onError)}
                                    />
                                    <ApplicationSubmitButton
                                        loading={submitLoading}
                                        app={app}
                                        sections={{ isComplete: isEligible && !submitDisabled }}
                                        onClick={actualSubmit}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </PortalView>
        </FormProvider>
    );
}

export default OTSG;
